<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-2 flex flex-col font-semibold lg:mt-32 lg:min-h-screen">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Notifications</h2>
    </header>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <ul v-else>
      <li class="my-6 py-1 flex items-center justify-between">
        <MessageIcon class="h-5 fill-current text-text-alternate-4" />
        <span class="flex-grow text-text-alternate-1 pl-3.5">SMS</span>
        <BButtonLoader
          :class="['h-5 w-5', sms ? 'ml-6' : 'mr-6']"
          v-if="smsResponse.isLoading"
        />
        <BToggle class="secondary" v-model="sms" v-else />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MobilePhoneIcon class="h-5 fill-current" style="fill: #577387" />
        <span class="flex-grow text-text-alternate-1 pl-3.5"
          >Push Notifications</span
        >
        <BButtonLoader
          :class="['h-5 w-5', push ? 'ml-6' : 'mr-6']"
          v-if="pushResponse.isLoading"
        />
        <BToggle class="secondary" v-model="push" v-else />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MessageIcon class="h-5 fill-current text-text-alternate-4" />
        <span class="flex-grow text-text-alternate-1 pl-3.5"
          >Email Notifications</span
        >
        <BButtonLoader
          :class="['h-5 w-5', email ? 'ml-6' : 'mr-6']"
          v-if="emailResponse.isLoading"
        />
        <BToggle class="secondary" v-model="email" v-else />
      </li>
    </ul>
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col font-semibold">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Notifications</h2>
    </header>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <ul v-else>
      <li class="my-6 py-1 flex items-center justify-between">
        <MessageIcon class="h-5 fill-current text-text-alternate-4" />
        <span class="flex-grow text-text-alternate-1 pl-3.5">SMS</span>
        <BButtonLoader
          :class="['h-5 w-5', sms ? 'ml-6' : 'mr-6']"
          v-if="smsResponse.isLoading"
        />
        <BToggle class="secondary" v-model="sms" v-else />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MobilePhoneIcon class="h-5 fill-current" style="fill: #577387" />
        <span class="flex-grow text-text-alternate-1 pl-3.5"
          >Push Notifications</span
        >
        <BButtonLoader
          :class="['h-5 w-5', push ? 'ml-6' : 'mr-6']"
          v-if="pushResponse.isLoading"
        />
        <BToggle class="secondary" v-model="push" v-else />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MessageIcon class="h-5 fill-current text-text-alternate-4" />
        <span class="flex-grow text-text-alternate-1 pl-3.5"
          >Email Notifications</span
        >
        <BButtonLoader
          :class="['h-5 w-5', email ? 'ml-6' : 'mr-6']"
          v-if="emailResponse.isLoading"
        />
        <BToggle class="secondary" v-model="email" v-else />
      </li>
    </ul>
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed, watch } from 'vue';
import { useStore } from 'vuex';
import {
  getNotificationsSettings,
  updateNotificationsSettings,
} from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import BToggle from '@/components/ui/BToggle';
const updateNotification = (userId, type) => (value) => {
  return updateNotificationsSettings({ userId, [type]: value });
};

export default {
  name: 'NotificationSetting',

  components: { BToggle },

  setup() {
    const store = useStore();
    const authUser = store.state.auth.authData;

    const [response, fetchUserNotifications] = useApi(getNotificationsSettings);
    const [smsResponse, updateSMSNotifications] = useApi(updateNotification(authUser.id, 'sms'));
    watch(smsResponse, ({ isSuccess }) => {
      if (isSuccess) {
        setTimeout(() => {
          response.data.sms = !response.data.sms;
        }, 20);
      }
    });

    const [pushResponse, updatePushNotifications] = useApi(updateNotification(authUser.id, 'pushNotifications'));
    watch(pushResponse, ({ isSuccess }) => {
      if (isSuccess) {
        setTimeout(() => {
          response.data.pushNotifications = !response.data.pushNotifications;
        }, 20);
      }
    });

    const [emailResponse, updateEmailNotifications] = useApi(updateNotification(authUser.id, 'emailNotifications'));
    watch(emailResponse, ({ isSuccess }) => {
      if (isSuccess) {
        setTimeout(() => {
          response.data.emailNotifications = !response.data.emailNotifications;
        }, 20);
      }
    });

    const sms = computed({
      set: updateSMSNotifications,
      get: () => response.data?.sms,
    });

    const push = computed({
      set: updatePushNotifications,
      get: () => response.data?.pushNotifications,
    });

    const email = computed({
      set: updateEmailNotifications,
      get: () => response.data?.emailNotifications,
    });

    fetchUserNotifications(authUser.id);

    return {
      ...toRefs(response),
      sms,
      push,
      email,
      smsResponse,
      pushResponse,
      emailResponse,
    };
  },
};
</script>
