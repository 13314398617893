<template>
  <label class="switch">
    <input type="checkbox" hidden v-model="isChecked" v-if="disabled" disabled />
    <input type="checkbox" hidden v-model="isChecked" v-else />
    <span class="slider round"></span>
  </label>
</template>

<script>
import { computed } from 'vue';
export default {
  props: ['modelValue', 'disabled'],

  setup(props, { emit }) {
    const isChecked = computed({
      get: () => props.modelValue,
      set: () => {
        !props.disabled && emit('update:modelValue', !props.modelValue);
      },
    });

    return { isChecked };
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bfcfda;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  // &:not([disabled]) {
  //   cursor: pointer;
  // }
}

.slider::before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #577387;
}

input:not([disabled]) + .slider {
  cursor: pointer;
}

input:focus + .slider {
  box-shadow: 0 0 1px #577387;
}

.secondary {
  .slider {
    background-color: #c4c4c4;
  }

  .slider::before {
    background-color: #696969;
  }

  input:checked + .slider::before {
    background-color: #e0f2ff;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
